import React, { useEffect } from "react";
import Layout from "../layout/Layout";
import { useDispatch } from "react-redux";
import { adminLogin, midwestCustomerLogin } from "../features/userSlice";
import useToastify from "../hooks/ui/useToastify";
import queryString from "query-string";
import { staticPage } from "../../config/staticPage";

const AdminCustomerLoginPage = ({ location }) => {
  const { toastMessage } = useToastify();
  const dispatch = useDispatch();

  // check when user logged in from admin...
  useEffect(() => {
    if (location.search.includes("token")) {
      const { token } = queryString.parse(location.search);
      dispatch(adminLogin({ token, toastMessage: toastMessage }));
    }
    // midwest customer redirect ...
    if (location.search.includes('et')) {
      const { et } = queryString.parse(location.search);
      dispatch(midwestCustomerLogin({ et, toastMessage: toastMessage }));
    }
  }, [location.search]);

  return (
    <>
      <div className="page-wrapper">
        <div className="page-heading-section">
          <div className="container">
            <p className="page-title">Loging In...</p>
          </div>
        </div>
      </div>
    </>
  );
};

export const Head = () => {
  let login_nav = staticPage;
  let loginDetails = null;

  for (const obj of login_nav) {
    if (obj.name === "customer-login") {
      loginDetails = obj;
      break; // Exit the loop once the desired object is found.
    }
  }
  return (
    <>
      <title>{loginDetails.title}</title>
      <meta name="description" content={loginDetails.description} />
    </>
  );
};

AdminCustomerLoginPage.Layout = Layout;
export default AdminCustomerLoginPage;
